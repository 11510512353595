<template>
  <a-row>
    <spinner v-if="loading" />
    <a-col v-show="!loading" class="height100 flex-1 faq-content">
      <a-tabs
        :active-key="$route.query.tab || 'content'"
        class="main-tabs"
        type="card"
        @change="$router.push({ query: { tab: arguments[0] } })"
      >
        <a-tab-pane key="content">
          <span slot="tab"> <a-icon type="project" />Содержимое </span>

          <!-- lang tabs -->
          <a-row align="middle" class="form_title sm-mb">
            <a-col :span="6" style="text-align: right; padding-right: 8px">
              Язык:
            </a-col>
            <a-col :span="12">
              <!-- lang translit tabs -->
              <a-row align="middle" class="flex-baseline flex-wrap">
                <lang-tabs v-model="langTab" class="mb-1x" />
                <a-button v-if="langTab === 'oz'" @click.stop="translitAction">
                  Перевести копию на
                  {{ langTab === "uz" ? "кирилицу" : "латиницу" }}
                </a-button>
              </a-row>
            </a-col>
          </a-row>

          <!-- title -->
          <a-row align="middle">
            <a-col :span="6" style="text-align: right; padding-right: 8px">
              Вопрос:
            </a-col>
            <a-col :span="12" class="sm-mb">
              <a-textarea
                v-model="form.question[langTab]"
                :auto-size="{ minRows: 3, maxRows: 5 }"
                placeholder="Вопрос"
              />
            </a-col>
          </a-row>
          <a-row align="middle" class="sm-mb">
            <a-col :span="6" style="text-align: right; padding-right: 8px">
              Ответ:
            </a-col>
            <a-col :key="$route.query.tab" :span="16" style="margin: 0 auto">
              <!-- :key="langtab" -->
              <editor
                :key="getKey"
                :value="form.answer[langTab]"
                :initial-value="form.answer[langTab]"
                :init="{
                  ...tinymce.default.props,
                  setup: (editor) => {
                    editor.ui.registry.addButton('spoiler-btn', {
                      icon: 'comment-add',
                      tooltip: 'Спойлер',
                      onAction: () => {
                        spoiler.modalVisibility = editor
                        spoilerHandler()
                      }
                    })
                    editor.ui.registry.addButton('storage-btn', {
                      icon: 'non-breaking',
                      tooltip: 'Хранилище изображений',
                      onAction: () => {
                        openStoreModal()
                        editorInstance = editor
                      }
                    })
                  }
                }"
                @input="
                  form.answer[langTab] = editorChangeHandler(arguments[0])
                "
              />
            </a-col>
          </a-row>
          <!-- position -->
          <a-row align="middle" class="flex-baseline">
            <a-col :span="6" style="text-align: right; padding-right: 8px">
              Позиция:
            </a-col>
            <a-col :span="12" class="sm-mb">
              <a-input
                v-model.number="form.order"
                placeholder="Введите позицию"
                spellcheck="false"
              />
              <div v-if="!form.position" class="error-text">
                Введите позицию.
              </div>
            </a-col>
          </a-row>
          <a-row style="margin-top: 25px">
            <a-col :span="6" style="text-align: right; padding-right: 8px">
              Статус:
            </a-col>
            <a-switch v-model="form.is_active" />
          </a-row>
        </a-tab-pane>
      </a-tabs>

      <a-row class="form-buttons__action" type="flex">
        <a-col :span="6" style="text-align: right; padding-right: 8px" />
        <a-col>
          <a-button :disabled="pending" @click="$router.go(-1)">
            <a-icon type="close" />Отмена
          </a-button>

          <a-button
            :loading="pending"
            style="margin-left: 10px"
            type="primary"
            @click="submit"
          >
            <a-icon type="check" />
            {{ form.id ? "Сохранить" : "Добавить" }}
          </a-button>
        </a-col>
      </a-row>
    </a-col>
  </a-row>
</template>

<script>
import cloneDeep from "lodash/cloneDeep"
export default {
  components: {
    editor: () => import("@tinymce/tinymce-vue")
  },
  data: () => ({
    langTab: $langPrefix,
    pending: false,
    loading: false,
    form: {
      question: {
        uz: "",
        oz: "",
        ru: "",
        en: "",
        qr: ""
      },
      answer: {
        uz: "",
        oz: "",
        ru: "",
        en: "",
        qr: ""
      },
      theme: null,
      order: 1,
      is_active: false
    }
  }),
  computed: {
    getKey() {
      return this.langTab
    }
  },
  async mounted() {
    await this.fetchData()
  },
  methods: {
    translitAction() {
      try {
        const from = this.langTab,
          to = this.langTab === "uz" ? "oz" : "uz",
          methodName = this.langTab === "uz" ? "toKirill" : "toLatin"

        if (this.form.question[from]) {
          this.form.question[to] = this[methodName](this.form.question[from])
        }

        if (this.form.answer[from]) {
          this.form.answer[to] = this[methodName](this.form.answer[from])
        }
      } catch (error) {
        console.log("translitAction error", error)
      }
    },
    async fetchData() {
      if (!this.$route.params.id) {
        return
      }
      this.loading = true
      try {
        const r = await this.$store.dispatch(
          "faq/detailQues",
          this.$route.params.id
        )
        this.form = {
          ...r,
          answer: {
            ru: r.answer_ru,
            en: r.answer_en,
            oz: r.answer_oz,
            uz: r.answer_uz,
            qr: r.answer_qr
          },
          question: {
            ru: r.question_ru,
            en: r.question_en,
            oz: r.question_oz,
            uz: r.question_uz,
            qr: r.question_qr
          }
        }
      } catch (error) {
        console.log(error)
        this.loading = false
      }

      this.loading = false
    },
    async submit() {
      this.pending = true
      const id = this.$route.params.id
      try {
        let newData = cloneDeep(this.form)
        newData = {
          ...newData,
          answer_ru: newData.answer.ru,
          answer_uz: newData.answer.uz,
          answer_oz: newData.answer.oz,
          answer_en: newData.answer.en,
          answer_qr: newData.answer.qr,
          question_ru: newData.question.ru,
          question_uz: newData.question.uz,
          question_oz: newData.question.oz,
          question_en: newData.question.en,
          question_qr: newData.question.qr
        }
        delete newData.answer
        delete newData.question
        let f = new FormData()
        Object.keys(newData).forEach((key) => {
          f.append(key, newData[key])
        })
        const r = await this.$store.dispatch(`faq/updateQuesAction`, {
          id: id,
          data: f
        })
        if (r && r.id) {
          this.$message.success("Данные сохранены")
          await this.$router.push({ name: "faq-ques" })
        }
      } catch (e) {
        console.log(e)
        this.pending = false
      }

      this.pending = false
    }
  }
}
</script>
